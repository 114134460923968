<template>
  <div>
    <b-col lg="12" v-if="show">
      <b-alert show dismissible fade class="mb-2" variant="warning">
        <div class="alert-body">
          <span>{{ $t("Base.Alert.please_wait") }}</span>
        </div>
      </b-alert>
    </b-col>
    <b-row v-if="!show">
      <b-col lg="12" sm="12">
        <b-card no-body class="card-company-table">
          <b-table
            :items="allUploadProfiles"
            responsive
            :fields="fields"
            primary-key="id"
            show-empty
            :empty-text="$t('Pages.Studio.Table.no_upload_profile')"
            class="position-relative"
          >
            <template #cell(self_declared_made_for_kids)="data">
              <div class="d-flex align-items-center">
                {{
                  $t(
                    "Pages.Studio.Table." +
                      data.item.self_declared_made_for_kids
                  )
                }}
              </div>
            </template>
            <template #cell(actions)="data">
              <div class="d-flex align-items-center">
                <span @click="editUploadProfile(data.item.id)">
                  <feather-icon
                    icon="EditIcon"
                    size="16"
                    class="mx-1 cursor-pointer"
                    v-b-modal.modal-edit-upload-profile
                  />
                </span>
                <span @click="deleteUploadProfile(data.item.id)">
                  <feather-icon
                    icon="TrashIcon"
                    size="16"
                    class="mx-1 cursor-pointer text-danger"
                  />
                </span>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>

    <!-- Create Upload Profile -->
    <b-modal
      id="modal-upload-profile"
      ref="mymodal"
      :ok-title="$t('Pages.Studio.Button.Save')"
      :cancel-title="$t('Pages.Studio.Button.Cancel')"
      centered
      :title="$t('Pages.Studio.Title.create_upload_profile')"
      @ok="createUploadProfile"
    >
      <b-form>
        <b-form-group>
          <label for="title"
            >{{ this.$t("Pages.Studio.Label.profile_title") }}:</label
          >
          <p v-if="showUploadProfileModalErrors" class="showError">
            {{
              this.$t("Pages.Studio.Alert." + this.showUploadProfileModalErrors)
            }}
          </p>
          <div>
            <b-form-input
              maxlength="150"
              id="title"
              type="text"
              v-model="newUploadProfile.profile_title"
              required
            />
          </div>
        </b-form-group>
        <b-form-group>
          <label for="title">{{ this.$t("Pages.Studio.Label.Title") }}:</label>
          <div>
            <b-form-input
              maxlength="150"
              id="title"
              type="text"
              v-model="newUploadProfile.title"
              required
            />
          </div>
        </b-form-group>
        <b-form-group>
          <label for="description"
            >{{ this.$t("Pages.Studio.Label.Description") }}:</label
          >
          <div>
            <b-form-textarea
              maxlength="5000"
              id="description"
              type="text"
              v-model="newUploadProfile.description"
              rows="8"
            />
          </div>
        </b-form-group>
        <b-form-group>
          <label for="description"
            >{{ this.$t("Pages.Studio.Label.for_children") }}:</label
          >
          <div>
            <b-form-checkbox v-model="newUploadProfile.forChildren" value="1">
              {{ this.$t("Pages.Studio.Label.yes_ok_children") }}
            </b-form-checkbox>
          </div>
          <div>
            <b-form-checkbox v-model="newUploadProfile.forChildren" value="0">
              {{ this.$t("Pages.Studio.Label.not_ok_children") }}
            </b-form-checkbox>
          </div>
        </b-form-group>
        <b-form-group label-for="vue-select">
          <label class="mt-1" for="description"
            >{{ this.$t("Pages.Studio.Label.Status") }}:</label
          >
          <v-select
            id="vue-select"
            dir="rtl"
            v-model="newUploadProfile.privacyStatus"
            :options="option"
          />
        </b-form-group>

        <b-form-group label-for="vue-select">
          <label class="mt-1"
            >{{ this.$t("Pages.Studio.Label.Category") }}:</label
          >
          <b-form-select v-model="newUploadProfile.category">
            <option
              v-for="(item, key) in this.categoriesAndLanguagesData.categories"
              :value="item"
            >
              {{ item.name }}
            </option>
          </b-form-select>
        </b-form-group>

        <b-form-group label-for="vue-select">
          <label class="mt-1"
            >{{ this.$t("Pages.Studio.Label.Language") }}:</label
          >
          <b-form-select v-model="newUploadProfile.language">
            <option
              v-for="(item, key) in this.categoriesAndLanguagesData.languages"
              :value="item"
            >
              {{ item.name }}
            </option>
          </b-form-select>
        </b-form-group>

        <b-form-group label-for="vue-select">
          <label class="mt-1"
            >{{ this.$t("Pages.Studio.Label.Playlists") }}:</label
          >
          <b-form-select v-model="newUploadProfile.playList">
            <option v-for="item in this.playLists.data" :value="item">
              {{ item.title }}
            </option>
          </b-form-select>
        </b-form-group>

        <b-form-group>
          <label for="tags" style="font-size: 16px"
            >{{ this.$t("Pages.Studio.Label.Tags") }}:
            <feather-icon
              :class="seo.hashtag ? 'text-info mx-1' : 'mx-1'"
              v-b-tooltip.hover.top="
                $t('Pages.Studio.Title.least_one_tag')
              "
              icon="AlertCircleIcon"
              size="16"
            />
            <feather-icon
              :class="seo.charactersTags ? 'text-info mx-1' : 'mx-1'"
              v-b-tooltip.hover.top="
                $t(
                  'Pages.Studio.Title.number_of_tag_between'
                )
              "
              icon="AlertCircleIcon"
              size="16"
            />
          </label>
          <b-form-tags
            id="tags"
            maxlength="500"
            v-model="newUploadProfile.tags"
            :placeholder="$t('Base.Placeholder.Tags')"
            :add-button-text="$t('Pages.Studio.Button.add_tag')"
            :tag-validator="tagValidator"
            separator=","
            :class="[seo.hashtag || seo.charactersTags ? 'border-warning' : '']"
          />

          <small class="mt-2">
            {{ $t("Pages.Studio.Title.Characters") }}: 500 /
            {{ newUploadProfile.countCharactersTags }}
          </small>
          <p v-if="seo.hashtag" class="text-info d-block">
            {{ $t("Pages.Studio.Title.least_one_tag") }}
          </p>
          <p v-if="seo.charactersTags" class="text-info d-block">
            {{
              $t(
                "Pages.Studio.Title.number_of_tag_between"
              )
            }}
          </p>
        </b-form-group>
      </b-form>
    </b-modal>
    <!-- Create Upload Profile -->

    <!-- Edit Upload Profile -->
    <b-modal
      id="modal-edit-upload-profile"
      ref="mymodal1"
      :ok-title="$t('Pages.Studio.Button.Save')"
      :cancel-title="$t('Pages.Studio.Button.Cancel')"
      centered
      :title="$t('Pages.Studio.Title.create_upload_profile')"
      @ok="updateUploadProfile"
    >
      <b-form>
        <b-form-group>
          <label for="title"
            >{{ this.$t("Pages.Studio.Label.profile_title") }}:</label
          >
          <p v-if="showUpdateUploadProfileModalErrors" class="showError">
            {{
              this.$t(
                "Pages.Studio.Alert." + this.showUpdateUploadProfileModalErrors
              )
            }}
          </p>
          <div>
            <b-form-input
              maxlength="150"
              id="title"
              type="text"
              v-model="editUploadProfileData.profile_name"
              required
            />
          </div>
        </b-form-group>
        <b-form-group>
          <label for="title">{{ this.$t("Pages.Studio.Label.Title") }}:</label>
          <div>
            <b-form-input
              maxlength="150"
              id="title"
              type="text"
              v-model="editUploadProfileData.title"
              required
            />
          </div>
        </b-form-group>
        <b-form-group>
          <label for="description"
            >{{ this.$t("Pages.Studio.Label.Description") }}:</label
          >
          <div>
            <b-form-textarea
              maxlength="5000"
              id="description"
              type="text"
              v-model="editUploadProfileData.description"
              rows="8"
            />
          </div>
        </b-form-group>
        <b-form-group>
          <label for="description"
            >{{ this.$t("Pages.Studio.Label.for_children") }}:</label
          >
          <div>
            <b-form-checkbox
              v-model="editUploadProfileData.self_declared_made_for_kids"
              value="1"
            >
              {{ this.$t("Pages.Studio.Label.yes_ok_children") }}
            </b-form-checkbox>
          </div>
          <div>
            <b-form-checkbox
              v-model="editUploadProfileData.self_declared_made_for_kids"
              value="0"
            >
              {{ this.$t("Pages.Studio.Label.not_ok_children") }}
            </b-form-checkbox>
          </div>
        </b-form-group>
        <b-form-group label-for="vue-select">
          <label class="mt-1" for="description"
            >{{ this.$t("Pages.Studio.Label.Status") }}:</label
          >
          <v-select
            id="vue-select"
            dir="rtl"
            v-model="editUploadProfileData.privacy_status"
            :options="option"
          />
        </b-form-group>

        <b-form-group label-for="vue-select">
          <label class="mt-1"
            >{{ this.$t("Pages.Studio.Label.Category") }}:</label
          >
          <b-form-select v-model="editUploadProfileData.category_namee">
            <option
              v-for="(item, key) in this.categoriesAndLanguagesData.categories"
              :value="item"
            >
              {{ item.name }}
            </option>
          </b-form-select>
        </b-form-group>

        <b-form-group label-for="vue-select">
          <label class="mt-1"
            >{{ this.$t("Pages.Studio.Label.Language") }}:</label
          >
          <b-form-select
            v-model="editUploadProfileData.default_audio_languagee"
          >
            <option
              v-for="(item, key) in this.categoriesAndLanguagesData.languages"
              :value="item"
            >
              {{ item.name }}
            </option>
          </b-form-select>
        </b-form-group>

        <b-form-group label-for="vue-select">
          <label class="mt-1"
            >{{ this.$t("Pages.Studio.Label.Playlists") }}:</label
          >
          <b-form-select v-model="editUploadProfileData.playListt">
            <option v-for="item in this.playLists.data" :value="item">
              {{ item.title }}
            </option>
          </b-form-select>
        </b-form-group>

        <b-form-group>
          <label for="tags" style="font-size: 16px"
            >{{ this.$t("Pages.Studio.Label.Tags") }}:
            <span style="display: block"
              >تگ های انتخاب شده: {{ this.editUploadProfileData.tags }}</span
            >
            <feather-icon
              :class="seo.hashtag ? 'text-info mx-1' : 'mx-1'"
              v-b-tooltip.hover.top="
                $t('Pages.Studio.Title.least_one_tag')
              "
              icon="AlertCircleIcon"
              size="16"
            />
            <feather-icon
              :class="seo.charactersTags ? 'text-info mx-1' : 'mx-1'"
              v-b-tooltip.hover.top="
                $t(
                  'Pages.Studio.Title.number_of_tag_between'
                )
              "
              icon="AlertCircleIcon"
              size="16"
            />
          </label>
          <b-form-tags
            id="tags"
            maxlength="500"
            v-model="editUploadProfileData.tagss"
            :placeholder="$t('Base.Placeholder.Tags')"
            :add-button-text="$t('Pages.Studio.Button.add_tag')"
            :tag-validator="tagValidator"
            separator=","
            :class="[seo.hashtag || seo.charactersTags ? 'border-warning' : '']"
          />

          <small class="mt-2">
            {{ $t("Pages.Studio.Title.Characters") }}: 500 /
            {{ newUploadProfile.countCharactersTags }}
          </small>
          <p v-if="seo.hashtag" class="text-info d-block">
            {{ $t("Pages.Studio.Title.least_one_tag") }}
          </p>
          <p v-if="seo.charactersTags" class="text-info d-block">
            {{
              $t(
                "Pages.Studio.Title.number_of_tag_between"
              )
            }}
          </p>
        </b-form-group>
      </b-form>
    </b-modal>
    <!-- Edit Upload Profile -->
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BAvatar,
  BTable,
  BImg,
  BAlert,
  BBadge,
  BButton,
  BLink,
  BTooltip,
  BMedia,
  BMediaAside,
  BFormTags,
  BMediaBody,
  BInputGroup,
  BInputGroupAppend,
  BCardHeader,
  BCardBody,
  BOverlay,
  BPaginationNav,
  BFormInput,
  BForm,
  BFormGroup,
  BModal,
  BFormCheckbox,
  BFormSelect,
  VBModal,
  BFormTextarea,
  VBTooltip,
} from "bootstrap-vue";

var moment = require("moment-jalaali");
import vSelect from "vue-select";
import MyDate from "@core/components/date/MyDate.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardBody,
    BCardText,
    BRow,
    BCol,
    BAvatar,
    BTooltip,
    VBTooltip,
    BTable,
    BFormTags,
    BImg,
    BFormCheckbox,
    BFormSelect,
    BLink,
    BButton,
    BAlert,
    BBadge,
    BCardHeader,
    vSelect,
    BOverlay,
    BPaginationNav,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BForm,
    BFormGroup,
    BModal,
    BFormTextarea,
  },
  data() {
    return {
      show: true,
      showUploadProfileModalErrors: false,
      showUpdateUploadProfileModalErrors: false,
      newUploadProfile: {
        profile_title: "",
        title: "",
        description: "",
        privacyStatus: "public",
        forChildren: "",
        category: "",
        updateProfileData: "",
        language: "",
        abbreviation: "",
        playList: "",
        tags: "",
        countCharactersTags: "",
      },
      option: ["public", "private", "unlisted"],
      allUploadProfiles: [],
      editUploadProfileData: "",
      categoriesAndLanguagesData: "",
      fields: [
        {
          key: "profile_name",
          label: this.$t("Pages.Studio.Table.profile_name"),
        },
        { key: "title", label: this.$t("Pages.Studio.Table.Title") },
        {
          key: "privacy_status",
          label: this.$t("Pages.Studio.Table.privacy_status"),
        },
        {
          key: "description",
          label: this.$t("Pages.Studio.Table.Description"),
        },
        { key: "tags", label: this.$t("Pages.Studio.Table.Tags") },
        {
          key: "default_audio_language",
          label: this.$t("Pages.Studio.Table.default_audio_lang"),
        },
        {
          key: "self_declared_made_for_kids",
          label: this.$t("Pages.Studio.Table.self_declared_kids"),
        },
        {
          key: "category_name",
          label: this.$t("Pages.Studio.Table.category_id"),
        },
        { key: "created_at", label: this.$t("Pages.Studio.Table.Date") },
        { key: "actions", label: this.$t("Pages.Studio.Table.Actions") },
      ],
      playLists: "",
      seo: {
        title: false,
        tagDescription: false,
        socialDescription: false,
        titleDescription: false,
        hashtag: false,
        charactersTags: false,
      },
      formCreate: {
        name_file: "",
        title: "",
        description: "",
        thumbnails: null,
        thumbnails_prew: null,
        defaultAudioLanguage: { label: "فارسی", value: "fa-IR" },
        category: { label: "Film & Animation", value: 1 },
        privacyStatus: "private",
        playlistsSelect: {
          label: this.$t("Pages.Studio.Title.not_playlist"),
          value: null,
        },
        btn: this.$t("Pages.Studio.Button.btn_create_vid"),
        titleForm: this.$t("Pages.Studio.Title.title_model_create_vid"),
        tags: [],
        countCharactersTags: 0,
        selfDeclaredMadeForKids: false,
        schedule: "",
      },

      MyDate,
    };
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  created() {
    this.getAllUploadProfiles();
    this.getCategoriesAndLanguagesData();
    this.getPlayLists();
  },
  methods: {
    getAllUploadProfiles() {
      this.$http
        .get(
          `/api/channel/studio/${this.$route.params.channel_id}/uploadProfile/list`
        )
        .then((response) => {
          this.allUploadProfiles = response.data;
          for (let date of this.allUploadProfiles) {
            let parsedDate = moment(date.created_at);
            date.created_at = parsedDate.format("YYYY/MM/DD");
          }
          this.show = false;
        });
    },
    createUploadProfile() {
      this.newUploadProfile.channel_id = this.$route.params.channel_id;
      this.$http
        .post(
          `/api/channel/studio/${this.$route.params.channel_id}/uploadProfile/create/new`,
          this.newUploadProfile
        )
        .then((response) => {
          if (response.status == 200) {
            this.getAllUploadProfiles();
            this.newUploadProfile = {};
            this.showUploadProfileModalErrors = false;
            this.makeToast(
              this.$t(""),
              this.$t("Pages.Studio.Alert.success_create_profile"),
              "success"
            );
          } else {
            this.$refs.mymodal.show();
            this.showUploadProfileModalErrors =
              response.response.data.error.data;
          }
        });
    },
    getCategoriesAndLanguagesData() {
      this.$http
        .get(`/api/channel/studio/videos/get-static-data-form`)
        .then((response) => {
          this.categoriesAndLanguagesData = response.data;
        });
    },
    getPlayLists() {
      this.$http
        .get(
          `/api/channel/studio/playlists/getList?channel_id=${this.$route.params.channel_id}`
        )
        .then((response) => {
          this.playLists = response.data;
        });
    },
    editUploadProfile(id) {
      this.$http
        .post(
          `/api/channel/studio/${this.$route.params.channel_id}/uploadProfile/${id}/edit`
        )
        .then((response) => {
          this.editUploadProfileData = response.data;
          for (let category of this.categoriesAndLanguagesData.categories) {
            if (category.name == this.editUploadProfileData.category_name) {
              this.editUploadProfileData.category_namee = category;
            }
          }
          for (let lang of this.categoriesAndLanguagesData.languages) {
            if (
              lang.name == this.editUploadProfileData.default_audio_language
            ) {
              this.editUploadProfileData.default_audio_languagee = lang;
            }
          }
          for (let playlist of this.playLists.data) {
            if (this.editUploadProfileData.playlist_title == playlist.title) {
              this.editUploadProfileData.playListt = playlist;
            }
          }
        });
    },

    deleteUploadProfile(id) {
      this.$swal({
        title: this.$t("Base.Alert.are_sure"),
        text: this.$t("Base.Alert.wont_able_revert"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("Base.Alert.del_it"),
        cancelButtonText: this.$t("Base.Alert.Cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$http
            .post(
              `/api/channel/studio/${this.$route.params.channel_id}/uploadProfile/${id}/delete`
            )
            .then((response) => {
              if (response.status == 200) {
                this.getAllUploadProfiles();
                this.makeToast(
                  this.$t(""),
                  this.$t("Pages.Studio.Alert.success_profile_del"),
                  "success"
                );
              } else {
                this.makeToast(
                  this.$t(""),
                  this.$t("Pages.Studio.Alert.err_del_profile"),
                  "warning"
                );
              }
            });
        }
      });
    },
    updateUploadProfile() {
      this.$http
        .post(
          `/api/channel/studio/${this.$route.params.channel_id}/uploadProfile/update/data`,
          this.editUploadProfileData
        )
        .then((response) => {
          if (response.status == 200) {
            this.editUploadProfileData = response.data;
            this.showUpdateUploadProfileModalErrors = false;
            this.showUploadProfileModalErrors = false;
            this.getAllUploadProfiles();
            this.makeToast(
              this.$t(""),
              this.$t("Pages.Studio.Alert.success_profile_update"),
              "success"
            );
          } else {
            this.$refs.mymodal1.show();
            this.showUpdateUploadProfileModalErrors =
              response.response.data.error.data;
          }
        });
    },
    tagValidator(tag) {
      this.formCreate.countCharactersTags =
        this.formCreate.tags.toString().length;
      if (
        this.formCreate.countCharactersTags <= 400 ||
        this.formCreate.countCharactersTags >= 500
      ) {
        this.seo.charactersTags = true;
      } else {
        this.seo.charactersTags = false;
      }
      if (
        this.formCreate.countCharactersTags < 500 &&
        this.formCreate.countCharactersTags + tag.length < 500
      ) {
        return tag;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.showError {
  color: red;
}
</style>
